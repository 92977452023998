/**
* Place all Z-Index values here and export where they are needed.
* Keep items is value order & don't use 9999 🤦🏼‍♂️
**/

$siteNavigation: 15;
$filtersBar: $siteNavigation - 1;
$datagridHeader: $siteNavigation - 2;
$dropdowns: $siteNavigation + 1;
$popover: $siteNavigation;
$tooltip: $siteNavigation + 3;
$popover: $siteNavigation;
$sitewideBanner: $siteNavigation - 1;
