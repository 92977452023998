@use "sass:math";
@use '~@THC/styles/core' as core;

@use '~@THC/styles/typography';

@use '~Utilities/styles/zindex' as zIndex;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

.component {
	&.component {
		:global(.mw-infoBox-wrapper) {
			background-color: var(--thc-color-dealer-black);
			z-index: zIndex.$sitewideBanner;

			&:before {
				background-color: var(--thc-semantic-info);
			}
		}

		> div {
			> div {
				padding-top: $marginGeneric * 3;

				@media (max-width: $breakpointTablet) {
					padding-left: $marginGeneric * 3;
					padding-right: $marginGeneric * 3;
				}
			}
		}

		.bannerWrapper {
			&:global(.isNotDismissable) {
				svg[class*='close'] {
					display: none;
				}
			}

			.title {
				&.title {
					align-items: flex-start;
					flex-direction: column;
					margin-bottom: 0;
					margin-top: 0;
					padding-left: 0;
					padding-right: 0;

					.info {
						@include body-fonts($white);

						padding-left: 0;

						&:not(.preserveNewLines) > br {
							@media (max-width: $breakpointMobile) {
								display: none;
							}
						}
					}
				}
			}

			.bannerTitleContainer {
				@include centered;

				@include typography.heading6();

				gap: core.$space3;
				margin-bottom: $marginGeneric * 2;
				padding-right: $marginGeneric * 5;

				@media (max-width: $breakpointMobile) {
					align-items: flex-start;
					flex-direction: column;
					margin-bottom: core.$space3;
				}
			}
		}
	}
}
